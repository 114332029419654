define([
    'lodash',
    'coreUtils',
    'santa-components'
], function (_, coreUtils, santaComponents) {
    'use strict';
    const {balataConsts} = coreUtils.mediaConsts;

    const posterQualityOverride = {
        unsharpMask: {
            radius: 0.33,
            amount: 1.0,
            threshold: 0.0
        }
    };

    return {
        mixins: [santaComponents.mixins.animationsMixin],
        // API
        externalAPI: ['play', 'pause', 'stop', 'seek', 'setVolume', 'mute', 'unMute', 'setRate', 'setSrc'],

        /**
         * export API function
         * @param {string} command
         * @param {object} params
         */
        mediaAPI(command, params = {}) {
            const apiCall = this[command];
            if (apiCall && _.includes(this.externalAPI, command)) {
                apiCall(params);
            }
        },
        getPosterImageComp() {
            const {shouldRenderSrc, imageUrlPreMeasureParams, filterEffect,
                hasBgScrollEffect, showMedia, compProp, compData, id, fittingType,
                alignType, style, posterWidth, posterHeight, parentId, renderType} = this.props;

            const posterProps = {
                ref: balataConsts.POSTER,
                key: style.backgroundColor ? 'posterImagePng' : 'posterImage',
                id: id + balataConsts.POSTER,
                containerWidth: posterWidth || 0,
                containerHeight: posterHeight || 0,
                containerId: parentId,
                shouldRenderSrc,
                imageUrlPreMeasureParams,
                filterEffect,
                hasBgScrollEffect,
                imageData: Object.assign({}, compData.posterImageRef, {quality: posterQualityOverride, devicePixelRatio: 1}),
                displayMode: fittingType,
                alignType,
                style: {position: 'absolute', top: 0, width: '100%', height: '100%'},
                autoLayout: true,
                wixImageLayout: renderType === 'bolt'
            };

            if (showMedia && compProp.animatePoster === 'fade') {
                this.animate(this.refs.poster, 'BaseFade', 1.6, 0, {opacity: 0, ease: 'Quad.easeOut'});
            } else {
                posterProps.style.opacity = showMedia ? 0 : 1;
            }

            return this.createChildComponent(compData.posterImageRef, 'core.components.Image', balataConsts.POSTER, posterProps);
        }
    };
});
